import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IAssignmentRole } from 'types/assignmentInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface IAssignmentRolePickerProps {
  /** The id of the field. */
  id?: string
  /** The current value. */
  value: IAssignmentRole | null
  /** Action to do when the value changes. */
  onChange: (newValue: IAssignmentRole | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
  /** The available options. */
  showNames: boolean
  /** Whether to show the names of the allocations. */
  assignmentRoles: IAssignmentRole[]
  /** Is the label inset. */
  insetLabel?: boolean
}

/**
 * Role picker component.
 *
 * @returns Component for picking assignment role.
 * @notExported
 */
const RolePicker: React.FC<IAssignmentRolePickerProps> = ({
  id,
  value,
  onChange,
  label = 'Role',
  required = false,
  disabled,
  assignmentRoles,
  showNames,
  insetLabel,
}) => {
  const { i18n } = useTranslation()

  const getRoleWithName = (option: IAssignmentRole) => {
    const getNames = () => {
      if (option.Allocations?.length > 0) {
        const nameArray: string[] = []
        for (const allocation of option.Allocations) {
          const firstName = allocation.Person.firstName
          const lastName = allocation.Person.lastName
          nameArray.push(` ${firstName} ${lastName}`)
        }
        return `(${nameArray} )`
      }
      return ''
    }
    const name = `${chooseDBTranslation(i18n, option.translations)?.name} ${getNames()} `
    return name
  }

  return (
    <>
      {!insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        id={id}
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
        disabled={disabled}
        value={value}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string') {
            onChange(newValue)
          }
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={showNames ? assignmentRoles : assignmentRoles.filter(role => role.Allocations.length < 1)}
        fullWidth
        getOptionLabel={option => {
          return showNames ? getRoleWithName(option) : chooseDBTranslation(i18n, option.translations).name
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id}>
              {showNames ? getRoleWithName(option) : chooseDBTranslation(i18n, option.translations).name}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            insetLabel={insetLabel}
            label={insetLabel ? label : undefined}
            name="roleId"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            required={required}
          />
        )}
      />
    </>
  )
}

export default RolePicker
