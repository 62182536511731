import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { assignmentAPI } from 'api/AssignmentAPI'
import { organizationAPI } from 'api/OrganizationAPI'
import { personAPI } from 'api/PersonAPI'
import { teamAPI } from 'api/TeamAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { NetworkId, OrganizationId, TeamId } from 'types/ids'
import { IPerson } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { orderBy } from 'lodash'
import { useIsComponentMounted } from 'hooks/util'
import { InputField } from '../InputField'

/** @notExported */
interface IPersonPickerProps {
  /** The id. */
  id?: string
  /** The current value. */
  value: IPerson | null
  /** The organization ID. */
  organizationId?: OrganizationId
  /** Action to do when the value changes. */
  onChange: (newValue: IPerson | null) => void
  /** The label. */
  label?: string | false
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled?: boolean
  /** The placeholder. */
  placeholder?: string
  /** The members. */
  members?
  /** Whether the field is for sales. */
  sales?: boolean
  /** The team ID. */
  teamId?: TeamId
  /** The network ID. */
  networkId?: NetworkId
  /** Whether the field is disable clearable. */
  disableClearable?: boolean
  /** The options. */
  options?: IPerson[]
  /** Whether the field is by organization. */
  byOrganization?: boolean
  /** Is the label inset. */
  insetLabel?: boolean
  /** The helper text. */
  helperText?: string
}

/**
 * Person picker component.
 *
 * @returns The person picker component.
 * @notExported
 */
const PersonPicker: React.FC<IPersonPickerProps> = ({
  id,
  value,
  organizationId,
  onChange,
  label = 'Person',
  required = false,
  disabled = false,
  placeholder,
  members = [],
  sales = false,
  teamId,
  networkId,
  disableClearable = false,
  options = [],
  byOrganization = false,
  insetLabel,
  helperText,
  ...rest
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [open, setOpen] = useState<boolean>(false)
  const [items, setItems] = useState<IPerson[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()
  const { i18n } = useTranslation()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        setLoading(true)
        let people: IPerson[] = []

        if (options.length) {
          people = options
        } else if (sales) {
          people = await assignmentAPI.getSalesPeople(controller)
        } else if (networkId) {
          people = await personAPI.getNetworkPeople(networkId, controller)
        } else if (teamId) {
          people = await teamAPI.getTeamMembers(teamId, controller)
        } else if (!organizationId) {
          people = []
        } else {
          people = await organizationAPI.getPersons(organizationId, controller)
        }

        if (people) {
          if (!isComponentMounted.current) return
          setItems(
            orderBy(
              people.map(person => ({
                ...person,
                fullName: `${person?.firstName} ${person?.lastName}`,
              })),
              ['organizationId'],
              ['asc']
            )
          )
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [organizationId, teamId, sales])

  const getPersonName = (item: IPerson) => {
    return `${item.firstName} ${item.lastName}`
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const memberIds = members.map(member => member.accountId)

  return (
    <>
      {!insetLabel && label !== false && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        id={id}
        open={open}
        loading={loading}
        disabled={disabled}
        value={value}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => onChange(newValue)}
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['fullName'])}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={
          items
            .filter(item => !memberIds.includes(item.accountId))
            .filter(item => !item.isPlaceholder) as (typeof items)[0][]
        }
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option
          }
          return getPersonName(option)
        }}
        isOptionEqualToValue={(option, value) => {
          if (typeof option === 'string' || typeof value === 'string') {
            return false
          }
          return option.id === value.id
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id}>
              {getPersonName(option)}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            insetLabel={insetLabel}
            fullWidth
            margin="dense"
            size="small"
            variant="outlined"
            placeholder={placeholder}
            label={insetLabel ? label : undefined}
            InputLabelProps={{ shrink: insetLabel ? true : undefined }}
            required={required}
            helperText={helperText}
          />
        )}
        fullWidth
        disableClearable={disableClearable}
        groupBy={
          byOrganization
            ? option => {
                if (option && option.Organization) {
                  return chooseDBTranslation(i18n, option.Organization).name
                }
                return `${option.organizationId}`
              }
            : undefined
        }
        {...rest}
      />
    </>
  )
}

export default PersonPicker
