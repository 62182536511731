import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IAssignment } from 'types/assignmentInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface IAssignmentPickerProps {
  /** The id.  */
  id?: string
  /**  The available options. */
  assignmentOptions: IAssignment[]
  /** The current value.  */
  value: IAssignment | null
  /** Action to do when the value changes.  */
  onChange: (newValue: IAssignment | null) => void
  /** The label text.  */
  label?: string
  /** Whether the field is required.  */
  required?: boolean
  /** Whether the field is disabled.  */
  disabled: boolean
  /** Is the label inset.  */
  insetLabel?: boolean
}

/**
 * Assignment picker component.
 *
 * @returns The assignment picker component.
 * @notExported
 */
const AssignmentPicker: React.FC<IAssignmentPickerProps> = ({
  id,
  assignmentOptions,
  value,
  onChange,
  label = 'Assignment',
  required = false,
  disabled,
  insetLabel,
}) => {
  const { i18n } = useTranslation()

  return (
    <>
      {!insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        id={id}
        disabled={disabled}
        value={value}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string') {
            onChange(newValue)
          }
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={assignmentOptions}
        getOptionLabel={option => {
          return chooseDBTranslation(i18n, option.translations).name
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.id}>
              {chooseDBTranslation(i18n, option.translations).name}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            label={insetLabel ? label : undefined}
            size="small"
            fullWidth
            margin="dense"
            variant="outlined"
            insetLabel={insetLabel}
            required={required}
          />
        )}
        fullWidth
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
      />
    </>
  )
}

export default AssignmentPicker
