import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import React from 'react'
import { IWidget } from 'types/adminInterfaces'
import { IAllocation } from 'types/allocationInterfaces'
import { TypeCode } from 'types/ids'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface ITypePickerProps {
  /** The id. */
  id?: string
  /** The current value. */
  value: TypeCode | null
  /** Action to do when the value changes. */
  onChange: (newValue: IAllocation | IWidget | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled: boolean
  /** The available options. */
  typeOptions
  /** Is the label inset. */
  insetLabel?: boolean
}

/**
 * Type picker component.
 *
 * @returns The type picker component.
 * @notExported
 */
const TypePicker: React.FC<ITypePickerProps> = ({
  id,
  value,
  onChange,
  label = 'Type',
  required = false,
  disabled,
  typeOptions,
  insetLabel,
}) => {
  const getSelectedValue = () => {
    if (value) {
      const selectedValueItem = typeOptions?.find(item => item.type === value)
      return selectedValueItem
    } else {
      return typeOptions[0]
    }
  }

  return (
    <>
      {!insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        id={id}
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['label'])}
        disabled={disabled}
        value={getSelectedValue()}
        options={typeOptions}
        fullWidth
        selectOnFocus
        disableClearable
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(selectedValue, option) => option.type === selectedValue.type}
        style={{ marginTop: '0px' }}
        onChange={(_event, newValue) => {
          if (newValue !== null) {
            onChange(newValue.type as unknown as IAllocation | IWidget)
          }
        }}
        renderInput={params => (
          <InputField
            {...params}
            name="type"
            label={insetLabel ? label : undefined}
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            insetLabel
            required={required}
          />
        )}
      />
      {getSelectedValue()?.info && (
        <Box width="90%" style={{ marginTop: 5 }}>
          <CardContentText type="date">{getSelectedValue().info}</CardContentText>
        </Box>
      )}
    </>
  )
}

export default TypePicker
